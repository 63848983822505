import React from "react"

import Layout from "../components/layout"
import ProjectHighlights from "../components/project-highlights"
import TextBlock from "../components/text-block"
import Seo from "../components/seo"
import LogoGrid from "../components/logo-grid"
import LogoGridMasonry from "../components/logo-grid-masonry"
import Map from "../components/map"
import Project from "../components/project"
import Intro from "../components/intro"

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <Seo />
    <Intro bg="green"></Intro>
    <TextBlock bg="green" id="expertise">
      <h1>Digital expertise since 1997.</h1>
      <p>
        For over two decades, we support agencies and businesses reaching their
        digital goals. We offer full service from strategy, design and
        development to deployment and support with a focus on consulting and
        long lasting partnerships.
      </p>
      <p>
        <strong>
          Let's <a href="/#contact">talk</a> about your ideas!
        </strong>
      </p>
    </TextBlock>
    <LogoGridMasonry
      bg="blue"
      title="Skills"
      id="skills"
      text={
        <>
          <p>
            We specialize in Drupal, the Jamstack and AWS services to offer
            tailor-made digital solutions to our customers, which are both
            scalable and resource effective. Our portfolio ranges from static
            websites and single page web apps to content management system
            driven websites and content hubs.
          </p>
          <p>
            <strong>
              Let's <a href="/#contact">talk</a> about your requirements!
            </strong>
          </p>
        </>
      }
      logos={[
        { icon: "storybook.svg", title: "Storybook" },
        { icon: "html.svg", title: "HTML5" },
        { icon: "css.svg", title: "CSS3" },
        { icon: "javascript.svg", title: "JavaScript", layout: "large" },
        { icon: "docker.svg", title: "Docker" },
        { icon: "git.svg", title: "Git" },
        { icon: "drupal.svg", title: "Drupal", layout: "large" },
        { icon: "atlassian.svg", title: "Atlassian" },
        { icon: "php.svg", title: "PHP" },
        { icon: "apis.svg", title: "Integrations" },
        { icon: "aws.svg", title: "Amazon Web Services", layout: "large" },
        { icon: "symfony.svg", title: "Symfony" },
        { icon: "reactjs.svg", title: "React" },
        { icon: "gatsbyjs.svg", title: "Gatsby" },
        { icon: "node.svg", title: "Node.js" },
      ]}
    ></LogoGridMasonry>
    <ProjectHighlights
      id="portfolio"
      title="Portfolio"
      projects={{
        macbook: ["cln", "sfgbb", "lngg"],
        ipad: ["fraai", "han", "gabo"],
        iphone: ["mtha", "bkg", "slz"],
      }}
      bg="black"
    ></ProjectHighlights>
    <Project projectKey="cln" />
    <Project projectKey="hsk" />
    <Project projectKey="sfgbb" />
    <Project projectKey="lngg" />
    <Project projectKey="maa" />
    <Project projectKey="fraai" />
    <Project projectKey="han" />
    <Project projectKey="gabo" />
    <Project projectKey="mtha" />
    <Project projectKey="bkg" />
    <Project projectKey="slz" />
    <LogoGrid
      bg="red"
      id="clients"
      title="Clients"
      logos={[
        { icon: "idl.svg", title: "idealo" },
        { icon: "sfgbb.svg", title: "Schule für Gestaltung Bern Biel" },
        { icon: "lngg.svg", title: "Langeoog" },
        { icon: "is.svg", title: "ImmobilienScout24" },
        { icon: "bp.svg", title: "Berliner Pilsner" },
        { icon: "bvg.svg", title: "BVG" },
        { icon: "byr.svg", title: "Bayer" },
        { icon: "aareon.svg", title: "Aareon" },
        { icon: "vivantes.svg", title: "Vivantes" },
        { icon: "frr.svg", title: "Ferrero" },
        { icon: "himss.svg", title: "himss" },
        { icon: "slz.svg", title: "Scholz" },
        { icon: "etl.svg", title: "ETL" },
        { icon: "bmtv.svg", title: "Berlin Music TV" },
        { icon: "maa.svg", title: "Münchner Ärztliche Anzeigen" },
        { icon: "fraai.svg", title: "FraaiBerlin" },
        { icon: "mtor.svg", title: "Marzahner Tor" },
        { icon: "rmed.svg", title: "Regiomed Kliniken" },
      ]}
    ></LogoGrid>
    <TextBlock bg="green" id="contact">
      <h1>Contact</h1>
      <p>
        <strong></strong>
      </p>
      <p>Let's discuss your ideas and requirements:</p>
      <p>
        <a href="/callback">+49 30 52001530</a>
      </p>
      <p>
        <a href="mailto:hello@patrickkrueger.com">hello@patrickkrueger.com</a>
      </p>
      <p
        style={{
          marginTop: "1em",
          display: "flex",
          justifyContent: "center",
          gap: "0.5em",
        }}
      >
        <a
          href="https://signal.me/#eu/9yCvdeMdzhoItFakHCE8o_ufPDS5tE6sU0fGtVJRFY3LlaJ1kwVGua07U5GhGBD0"
          target="_blank"
          rel="noreferrer"
          style={{
            border: "none",
            padding: "0.25em",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "0.33em",
          }}
        >
          <img
            src="/icons/signal-messenger-brands.svg"
            alt="Signal"
            style={{
              width: "1em",
              height: "1em",
              filter: "invert(1)",
            }}
          />
          Signal
        </a>
        <a
          href="https://wa.me/message/X7CGJKCO24SJJ1"
          target="_blank"
          rel="noreferrer"
          style={{
            border: "none",
            padding: "0.25em",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "0.33em",
          }}
        >
          <img
            src="/icons/whatsapp-brands.svg"
            alt="WhatsApp"
            style={{
              width: "1em",
              height: "1em",
              filter: "invert(1)",
            }}
          />
          WhatsApp
        </a>
      </p>
    </TextBlock>
    <Map bg="blue" title="Location" id="location">
      <p>
        <a
          class="secondary"
          target="_blank"
          href="https://g.page/r/CTQbhwKiZxeTEBA"
          rel="noreferrer"
        >
          <strong>patrickkrueger.com</strong>
          <br />
          Marienstraße 7<br />
          10117 Berlin-Mitte
        </a>
      </p>
    </Map>
  </Layout>
)

export default IndexPage
